<script setup lang="ts">
const localePath = useLocalePath()
const config = useRuntimeConfig()
const { isSmallAndMediumScreen, isMobile } = useBreakpoint()
const { trackEvent } = useTracking()
const { unsignedClientContracts, unsignedOwnerContracts } = useAuth()
const { headerHeight } = useHeaderNavigation()
const { sendExposure } = useExperimentExposure()
const { user, hasMobileApp, isOwner, modifyUserInformations } = useUser()

const modals = ref({
  showClientContract: false,
  showOwnerContract: false,
  showTerms: false,
  bannerMessage: true,
})
const activeFeatureTouchpoint = config.public.touchpointFeature === 'true'

const isBannerDisplayed = computed({
  get() {
    return (
      (modals.value.bannerMessage && isOwner.value && !hasMobileApp.value) ||
      (modals.value.bannerMessage && activeFeatureTouchpoint)
    )
  },
  set(value) {
    modals.value.bannerMessage = value
  },
})

const redirectClientContract = () => {
  let path = ''

  if (unsignedClientContracts.value.length > 0) {
    path = localePath({
      name: 'reservationAgreement-id-identityDocument',
      params: {
        id: unsignedClientContracts.value[0],
      },
    })
  }
  closeModal('showClientContract')

  navigateTo(path)
}

const redirectOwnerContract = () => {
  let path = ''

  if (unsignedOwnerContracts.value.length > 0) {
    path = localePath({
      name: 'reservationAgreement-id-reservationRequest',
      params: {
        id: unsignedOwnerContracts.value[0],
      },
    })
  }
  closeModal('showOwnerContract')

  navigateTo(path)
}

// Init header
const cssVar = computed(() => {
  return {
    '--header-height': `${headerHeight.value}px`,
  }
})

modals.value.showTerms = !user.value.termsOfServiceSigned
modals.value.showClientContract = unsignedClientContracts.value.length > 0
modals.value.showOwnerContract = unsignedOwnerContracts.value.length > 0

const handleClick = async () => {
  await modifyUserInformations({
    user: {
      terms_of_service_signed: true,
    },
  })

  closeModal('showTerms')
}

const closeModal = (modalName: keyof typeof modals.value) => {
  modals.value[modalName] = false
}

const trackClick = () => {
  trackEvent({
    event: 'touchpoint_lp_store',
    source: 'account',
  })
}
defineSlots<{ default(): any }>()

onMounted(() => {
  sendExposure('multi-inquiry-from-search')
})
</script>

<template>
  <div class="account-layout" :style="cssVar">
    <TheHeader :disable-affix="isMobile" />
    <TheMenuMobile v-if="isSmallAndMediumScreen" />
    <AccountBannerMessage
      v-model="isBannerDisplayed"
      :text="isOwner ? 'account.downloadAppMobileOwner' : 'account.downloadApp'"
      variant="beige"
      @targetClick="trackClick"
    />

    <div class="account-layout__content">
      <BaseContainer>
        <BaseRow class="justify-center">
          <BaseCol v-if="!$device.isMobile" cols="24" md="10" lg="8">
            <BaseStickySide class="account-layout__menu">
              <AccountMenu :is-banner-displayed="isBannerDisplayed" />
            </BaseStickySide>
          </BaseCol>
          <BaseCol cols="24" md="14" lg="16">
            <slot />
          </BaseCol>
        </BaseRow>

        <BaseModalV2
          v-model="modals.showOwnerContract"
          :primary-button="$t('account.modal.unsignedContract.owner.button')"
          data-cy="modal-owner-contract"
          @primary-button="redirectOwnerContract"
        >
          <template #content>
            <h2 class="mb-4 mt-1 text-center text-4xl">
              {{ $t('account.modal.unsignedContract.titleOwner') }}
            </h2>
            <p class="mb-8 whitespace-pre-line text-center">
              {{ $t('account.modal.unsignedContract.contentOwner') }}
            </p>
          </template>
        </BaseModalV2>

        <BaseModalV2
          v-model="modals.showClientContract"
          :primary-button="$t('account.modal.unsignedContract.client.button')"
          data-cy="modal-client-contract"
          @primary-button="redirectClientContract"
        >
          <template #content>
            <h2 class="mb-4 mt-1 text-center text-4xl">
              {{ $t('account.modal.unsignedContract.titleClient') }}
            </h2>
            <p class="mb-8 whitespace-pre-line text-center">
              {{ $t('account.modal.unsignedContract.contentClient') }}
            </p>
          </template>
        </BaseModalV2>

        <BaseModalV2
          v-model="modals.showTerms"
          :has-close-button="false"
          data-cy="modal-terms-of-service"
          :primary-button="$t('account.modal.termsOfServiceSigned.button')"
          @primary-button="handleClick"
        >
          <template #content>
            <h2 class="mb-10 mt-1 text-center text-4xl">
              {{ $t('account.modal.termsOfServiceSigned.title') }}
            </h2>
            <i18n-t
              class="mb-10"
              keypath="account.modal.termsOfServiceSigned.content"
              tag="p"
            >
              <template #link>
                <NuxtLink :to="localePath('legal-termsOfUse')" target="_blank">
                  {{ $t('account.modal.termsOfServiceSigned.link') }}
                </NuxtLink>
              </template>
            </i18n-t>
          </template>
        </BaseModalV2>
      </BaseContainer>
    </div>

    <TheFooter ref="footer" />
  </div>
</template>

<style>
.account-layout {
  @apply min-h-screen flex flex-col;
}

.account-layout__content {
  @apply flex flex-auto;
}

.account-layout__menu {
  margin: 1.5rem 0;
}

@screen md {
  .account-layout__menu {
    margin: 2rem 0;
    padding-right: 2rem;
  }
}

.account-layout__title {
  @apply text-6xl text-center;
}

@screen md {
  .account-layout__title {
    @apply text-left;
  }
}
</style>
